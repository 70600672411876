import Hls from 'hls.js'

// if (Hls.isSupported()) {
const videoPlayer = document.querySelector('video')
console.log(videoPlayer)
const video = document.querySelector('.video')
console.log(video)
const videoSrc = video.dataset.src
console.log(videoSrc)
if (Hls.isSupported()) {
    var hls = new Hls()
    hls.loadSource(videoSrc)
    hls.attachMedia(videoPlayer)
    // console.log(videoPlayer.canPlayType('application/vnd.apple.mpegurl') ? 'true' : 'false')
    // videoPlayer.play()
    // run media attach event handler
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {

        videoPlayer.addEventListener('canplaythrough', () => {
            const playing = !!(videoPlayer.currentTime > 0 && !videoPlayer.paused && !videoPlayer.ended && videoPlayer.readyState > 2)
            // pause initially
            if (!playing) {
                videoPlayer.muted = true
                videoPlayer.pause()
            }
            videoPlayer.play()
        })
    })
}
// HLS.js is not supported on platforms that do not have Media Source
// Extensions (MSE) enabled.
//
// When the browser has built-in HLS support (check using `canPlayType`),
// we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
// element through the `src` property. This is using the built-in support
// of the plain video element, without using HLS.js.
// else if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
//     videoPlayer.src = videoSrc
//     videoPlayer.play()
// }
// }